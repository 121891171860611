<template>
  <div class="book-detail">
    <div class="cover-info">
      <img :src=this.bookImgUrl class="book-img">
      <span class="book-title">{{ bookItem.name }}</span>
    </div>
    <div class="audios">
      <div class="audio-title">
        <p>课本音频</p>
      </div>
      <div class="audio-content">
        <!--eslint-disable-next-line-->
        <div v-for="item in this.audioItems" class="audio-item">
          <p>{{ item.name }}</p>
          <!--          eslint-disable-next-line-->
          <img v-if="item.audioLink" @click="changeAudioLink(item)" :src="require('../assets/book/play.png')" class="playIcon"/>
        </div>
        <div class="player">
<!--          <audio :src="audioLink" controls="controls" autoplay="autoplay" style="width: 100%">-->
<!--          </audio>-->
          <aplayer autoplay
                   :music="this.play" ref="aplayer"
          />
        </div>

      </div>

    </div>
  </div>

<!--  <div>-->
<!--    这是被跳转的页面-->
<!--  </div>-->
</template>
<script>
import {bookList} from '/src/data/data.js'
import Aplayer from 'vue-aplayer'
export default {
  name: "BookDetail",
  data(){
    return{
      book : bookList[0],
      audioLink: null,
      play:{
        src:" ",
        pic:" ",
        title: " ",
        artist:" "
      }
      // playButtonImg:require("@/assets/play.png")
    }
  },
  components:{
    Aplayer
  },
  computed:{
    audioItems: function (){
      let data = []
      for (let i = 0; i < this.bookItem.units.length; i++) {
        let unit = this.bookItem.units[i];
        let contextItem = {};
        contextItem.name = unit.unit;
        data.push(contextItem);
        for (let j = 0; j < unit.pages.length; j++) {
          let name = unit.pages[j].name;
          let audioLink = unit.pages[j].audioLink;
          let contextItemWithAudio = {};
          contextItemWithAudio.name = name;
          contextItemWithAudio.audioLink = audioLink;
          data.push(contextItemWithAudio);
        }
      }
      console.log("audioItems测试")
      console.log(data)
      console.log(this.bookItem)
      return data;
    },
    // bookId:null,
    bookItem:function (){
      if(this.bookId===null){
        return bookList[0];
      }else {
        let number = parseInt(this.bookId);
        for (let i = 0; i < bookList.length; i++) {
          if(bookList[i].index === number){
            return bookList[i]
          }
        }
        return bookList[0];
      }
    },
    bookImgUrl:function (){
      console.log("imgLink debug")
      console.log(this.bookItem)
      return this.bookItem.imgLink;
    }
  },
  methods:{
    changeAudioLink(bookAudioItem){
      console.log(bookAudioItem);
      let newPlay = {}
      newPlay.src=bookAudioItem.audioLink
      newPlay.title=bookAudioItem.name
      newPlay.pic=this.bookImgUrl
      newPlay.artist=" "
      this.play = newPlay
      // this.audioLink = bookAudioItem.audioLink;
      // this.play.src=bookAudioItem.audioLink
      // this.play.title=bookAudioItem.name
      console.log(this.$refs.aplayer)
      this.$refs.aplayer.thenPlay()
    }
  },
  created() {
    // console.log("创建页面成功")
    this.bookId = this.$route.params.bookId;
    console.log(this.bookId)
    let newPlay = {}
    newPlay.src=" "
    newPlay.title=" "
    newPlay.pic=this.bookImgUrl
    newPlay.artist=" "
    this.play = newPlay
    // console.log(this.bookItem)
  }
}
</script>

<style scoped>
.cover-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.book-img {
  width: 150px;
  height: 150px;
  border-radius: 3px;
  margin: 5px;
}

.book-title {
  font-weight: bold;
}

.book-detail {
  background: #f2f2f2;
  padding: 10px;
}

.audios {
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  align-items: flex-start;
}

.audio-title {
  font-weight: 700;
  display: flex;
  border-bottom: .05rem solid #ddd;
  padding: 0 12px;
  margin-bottom: 8px;
  align-items: center;
}
.audio-content{
  /*border-bottom: .05rem solid #eee;*/
  width: 100%;
}
.audio-content div{
  width: 100%;
  margin-bottom: 0.5rem;
}
.audio-item{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.player {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 100px;
}
.playIcon{
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
.audio-item p{
  padding-left: 10px;
}
</style>
